.App {
  text-align: center;

  &-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }

  &-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  &-link {
    color: #61dafb;
  }
}

.timeline {
  &__loader {
    align-items: center;
    display: flex;
    flex: 1;
    height: 300px;
    justify-content: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
